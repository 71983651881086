<template>
  <b10-legend
    :showing.sync="internalShowing"
  >
    <template
      slot="legend"
    >
      <v-simple-table>
        <template
          #default
        >
          <tbody>
            <tr
              v-for="item in CITA.estado"
              :key="item.estados"
            >
              <td>
                <v-chip
                  :color="CITA.coloresEstado[item]"
                  dark
                  small
                  label
                  class="mr-1"
                >
                  <strong>Cita</strong>
                </v-chip>
              </td>
              <td>
                {{ CITA.descripcionesEstado[item] }}
              </td>
            </tr>
            <tr
              v-for="(item, index) in OPERACIONCRM.prioridad"
              :key="index"
            >
              <td>
                <v-chip
                  :color="OPERACIONCRM.coloresPrioridad[item]"
                  dark
                  small
                  label
                  class="mr-1"
                >
                  <strong>Operaciones</strong>
                </v-chip>
              </td>
              <td>
                Prioridad {{ $options.filters.lowercase(OPERACIONCRM.descripcionesPrioridad[item]) }}
              </td>
            </tr>
            <tr
              v-for="(item, index) in tiposDeActuacionLegend"
              :key="index"
            >
              <td>
                <v-chip
                  :color="item.color"
                  dark
                  small
                  label
                  class="mr-1"
                >
                  <strong>Parte de trabajo</strong>
                </v-chip>
              </td>
              <td>
                {{ item.descripcion }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>
  </b10-legend>
</template>

<script>
import internalShowingMixin from '@/mixins/internalShowingMixin'
import { CITA, OPERACIONCRM } from '@/utils/consts'

export default {
  mixins: [internalShowingMixin],
  props: {
    tiposDeActuacionLegend: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      CITA,
      OPERACIONCRM
    }
  },
}
</script>
