import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'
import { firstDayOfMonth, lastDayOfMonth, endOfDay } from '@/utils/date'
import { PARTE_TRABAJO } from '@/utils/consts'

export default {
  async selectAgenda (Vue, idempleado, fecha, idtecnico) {
    let apiCalls = []
    const apiFilterCita = new APIFilter()
    const fdesde = firstDayOfMonth(fecha)
    const fhasta = lastDayOfMonth(fecha)
    apiFilterCita
      .addExact('idempleado', idempleado)
      .addGTE('fcita', fdesde)
      .addLTE('fcita', fhasta)
    apiCalls.push({ name: 'selectCita', method: 'cita.select', params: { filter: apiFilterCita } })
    const apiFilterOperacionCRM = new APIFilter()
    apiFilterOperacionCRM
      .addExact('idresponsable', idempleado)
      .addExact('abierta', true)
      .addGTE('fproximarevision', fdesde)
      .addLTE('fproximarevision', fhasta)
    apiCalls.push({ name: 'selectOperacionCRM', method: 'operacioncrm.select', params: { filter: apiFilterOperacionCRM } })
    const apiFilterParteTrabajo = new APIFilter()
    apiFilterParteTrabajo
      .setOperator(API_FILTER_OPERATORS.AND)
      .addGTE('fparte', firstDayOfMonth(fecha))
      .addLTE('fparte', endOfDay(lastDayOfMonth(fecha)))
      .addExact('estado', PARTE_TRABAJO.estados.pendiente)
      .addExact('idtecnico', idtecnico)
    apiCalls.push({ name: 'selectParteTrabajo', method: 'parteTrabajo.selectAsignados', params: { filter: apiFilterParteTrabajo } })
    return await Vue.$api.batchCall(apiCalls)
  }
}
